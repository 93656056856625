import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path1/node_modules/.pnpm/@clerk+nextjs@5.0.2_eslint@8.57.0_next@14.2.16_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@p_xopkhcpjrvqvgpzyrwrcae3pty/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path1/node_modules/.pnpm/@clerk+nextjs@5.0.2_eslint@8.57.0_next@14.2.16_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@p_xopkhcpjrvqvgpzyrwrcae3pty/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path1/node_modules/.pnpm/@clerk+nextjs@5.0.2_eslint@8.57.0_next@14.2.16_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@p_xopkhcpjrvqvgpzyrwrcae3pty/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","__experimental_GoogleOneTap"] */ "/vercel/path1/node_modules/.pnpm/@clerk+nextjs@5.0.2_eslint@8.57.0_next@14.2.16_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@p_xopkhcpjrvqvgpzyrwrcae3pty/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@playwright+test@1.48.2_react-dom@18_bjwwdiso6mhfrdpnrttejgr7vu/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_@opentelemetry+api@1.8.0_@playwright+test@1.48.2_react-dom@18_bjwwdiso6mhfrdpnrttejgr7vu/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/app/(app)/_modules/header/desktop-navigation-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/app/(app)/_modules/header/inbox/inbox-shelf.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/app/(app)/_modules/header/mobile-nav-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/app/(app)/_modules/header/organization-select/organization-select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path1/src/ui/shadcn/ui/separator.tsx");
